import React from "react";
import { Link } from "gatsby";

import Layout from "../components/Layout";
import Title from "../components/Title";

const ThankYouPage = ({ path }) => {
  return (
    <Layout currentPath={path} title="Contact Us">
      <Title label="Contact Us" />

      <section className="px-4 py-8 container mx-auto">
        <p className="my-4 text-lg text-gray-700 dark:text-gray-300">
          We will get in touch with you shortly.
        </p>

        <div className="flex">
          <Link
            className="text-primary-600 font-semibold text-lg dark:text-primary-200"
            to="/"
          >
            Back to Home &#8594;
          </Link>
        </div>
      </section>
    </Layout>
  );
};

export default ThankYouPage;
